export default {
    locale: 'en',
    header: {
        about: 'About',
        tours: 'Tours',
        excursions: 'Excursions',
        contacts: 'Contacts',
    },
    footer: {
        contacts: 'Contacts',
        links: {
            about: 'About',
            tours: 'Tours',
            excursions: 'Excursions',
            how: 'How to order and pay',
            policy: 'Personal data processing policy',
            agents: 'Agents',
            requisites: 'Requisites',
        },
    },
    cookie: {
        button: 'Accept',
        text: {
            first: 'We are using files ',
            second: 'to personalize and improve your experience on our website. By using our site you agree to our use of cookies.',
        },
    },
    'main-page': {
        subtitle: 'Tour Operator',
        tagline: {
            'text-1': {
                first: 'R',
                second: 'ealization of your',
            },
            'text-2': {
                first: 'V',
                second: 'ision under our',
            },
            'text-3': {
                first: 'M',
                second: 'anagement',
            },
        },
        submit: 'Submit your application',
        about: {
            title: 'About the company',
            subtext: {
                'text-1': {
                    first: 'RVM - ',
                    second: 'tour operator',
                },
                'text-2': 'by international entry and',
                'text-3': 'domestic tourism',
            },
            register: 'Our number in the Unified Federal Register of Tour Operators is RTO 018270',
            league: 'We are members of the League of Tour Operators of St. Petersburg',
            safety: 'We have direct contracts with museums, transport companies and hotels of various categories',
            credibility:
                'We meet all the requirements for participation in tenders of state and budgetary organizations',
            more: 'More details',
        },
        'fourth-section': {
            first: "Let's organize",
            second: 'tours and excursions',
            third: 'across St. Petersburg and Russia',
        },
        tours: {
            title: 'Tours',
            spb: 'St. Petersburg',
            russia: 'Russia',
            foreign: 'Foreign countries',
            school: 'Tours for schoolchildren',
            medicine: 'Health holiday',
            water: 'Water and Russian Railways cruises',
        },
        excursion: {
            title: 'Excursions',
        },
        additional: {
            title: 'Additional services',
            organization: 'Event organization',
            reserv: 'Hotel Reservations',
        },
        'ninth-section': {
            first: 'individual approach',
            second: 'professionalism',
            third: 'reliability',
        },
    },
    'empty-page': 'Page under construction',
    agents: 'Agents',
    how: 'How to pay and order',
    common: {
        people: ' ₽/person',
    },
    excursion: {
        title: 'Excursions',
        first: {
            title: 'Petersburg Triangle',
            text: 'Hiking',
        },
        second: {
            title: 'A fortress guarded by an angel',
            text: 'Hiking',
        },
        third: {
            title: 'What the courtyards of Vasilyevsky Island are silent about',
            text: 'Hiking',
        },
        fourth: {
            title: 'Journey to the Age of Enlightenment',
            text: 'Excursion to the Russian National Library',
        },
        fifth: {
            title: 'Favorite cup',
            text: 'Excursion to the Imperial Porcelain Factory and master class',
        },
    },
    tours: {
        title: 'Tours',
        first: {
            title: 'Tours to St. Petersburg',
            text: 'Capital of Culture: palaces, bridges and canals',
        },
        second: {
            title: 'Tours to Moscow',
            text: "Heart of Russia: Kremlin Square, St. Basil's Cathedral and VDNKh",
        },
        third: {
            title: 'Tours in Russia',
            text: 'Murmansk and northern lights, Kazan and Kazan Kremlin',
        },
        fourth: {
            title: 'Tours for schoolchildren',
            text: 'Cultural program for children and teenagers',
        },
        fifth: {
            title: 'Health tours',
            text: 'Spa complexes and sanatoriums: combining business with pleasure',
        },
        sixth: {
            title: 'Tours for active recreation',
            text: 'Eco-trails and sports activities',
        },
        general: {
            subtext:
                'You can choose a ready-made program or send a request for a personal program, which we will create in accordance with your wishes.',
            submit: 'Leave a request',
            spb: {
                'title-1': 'Immortal classic',
                'text-1':
                    'Getting to know St. Petersburg: a review of iconic museums and palaces that will not leave anyone indifferent.',
                'title-2': 'For experienced tourists',
                'text-2':
                    'There is always something to see in St. Petersburg! Even a city regular will find something to see.',
                days: '5 days',
            },
        },
    },
    contacts: {
        title: 'RVM address',
        address: {
            first: 'St. Petersburg, business center “CITY”,',
            second: 'Sadovaya street 12, office 3',
            third: '3 minutes from the station. Metro Gostiny Dvor"',
        },
        subtitle: 'Office hours',
        schedule: {
            first: 'Monday to Friday - from 10:00 to 18:00',
            second: 'On weekends - by appointment',
        },
        form: {
            title: 'Feedback form',
            subtitle: 'Write to us with any questions or suggestions',
            name: 'Your name*',
            email: 'Your email',
            phone: 'Your phone number*',
            message: 'Your message',
            policy: {
                first: 'I agree to the processing of personal data under the conditions set out in ',
                second: 'Consent to the processing of personal data ',
                third: 'and ',
                fourth: 'Policy.',
            },
            send: 'Send',
            thanks: {
                first: 'Thank you for your application!',
                second: 'Our specialist will contact you shortly.',
            },
        },
    },
    about: {
        title: 'About company',
        text: {
            first: "The name of our company stands for: “Let's make your dreams come true.” This is both the company's slogan and our attitude towards our clients.",
            second: 'Some people dream of long journeys, while others dream of a weekend trip close to home. We are ready to make all your wishes come true!',
        },
        tagline: {
            'text-1': {
                first: 'R',
                second: 'ealization of your',
            },
            'text-2': {
                first: 'V',
                second: 'ision under our',
            },
            'text-3': {
                first: 'M',
                second: 'anagement',
            },
        },
        labels: {
            years: 'years on the tourism market',
            success: 'successfully implemented programs',
            direction: 'main activities',
        },
        list: {
            'text-1': {
                first: 'In 2024, the company celebrated ',
                second: '9 years ',
                third: 'since the beginning',
            },
            'text-2': {
                first: 'Since then, RVM employees have organized ',
                second: 'more than 100 ',
                third: 'various programs for both small groups and teams of 100+ people.',
            },
            'text-3': {
                first: 'Tour operator RVM stands out for its personal elaboration of each program according to ',
                second: '3 main areas:  ',
                third: 'tours in St. Petersburg and Russia, excursions and events (conferences, weddings, etc.) in St. Petersburg.',
            },
        },
        guarantee: {
            title: 'We guarantee',
            clients: {
                title: 'To our clients',
                first: 'high level of services',
                second: 'safety and security',
                third: 'making all your wishes come true',
                fourth: 'positive emotions before, after and during relaxation',
            },
            partners: {
                title: 'To our partners',
                first: 'mutually beneficial cooperation',
                second: 'professional approach to work',
                third: 'reliability of all stages of interaction',
                fourth: 'punctuality and organization',
            },
        },
        team: {
            title: 'Our team',
            first: {
                post: 'CEO',
                name: 'Lyudmila Adamovich',
            },
            second: {
                post: 'Executive Director',
                name: 'Una Wygowska',
            },
            third: {
                post: 'Head of Sales Department',
                name: 'Elizaveta Shved',
            },
            fourth: {
                post: 'Head of Inbound Tourism Department',
                name: 'Maria Kritskaya',
            },
        },
        awards: {
            title: 'Our awards',
        },
    },
    consent: {
        title: 'Consent to the Processing of Personal Data',
        text: {
            '1': 'I, an individual whose information is specified in the forms I filled out:',
            '2': '“Contacts” ',
            '3': '“Feedback Form” ',
            '4': '“Booking” ',
            '5': '“Booking” ',
            '6': "(hereinafter referred to as the 'Applicant'), hereby give my consent to the processing by LLC 'RVM' (TIN 7840042552, 191023 St. Petersburg, Sadovaya 12, Lit. A, room 30-N, office 3) (hereinafter referred to as the 'Company') of the following my personal data: last name, first name, patronymic, residence address, email address, mobile phone number, landline phone number (hereinafter referred to as 'Personal Data'), and I confirm that by giving such consent, I act by my own will and in my own interest.",
            '7': "The consent is given by me for the purpose of contacting me and providing me with the information I am interested in according to the completed 'Feedback Form'.",
            '8': "The processing of the Applicant's Personal Data is carried out by the Company to the extent necessary to achieve the above purpose, in compliance with the requirements of the legislation of the Russian Federation regarding confidentiality and ensuring the security of Personal Data during their processing, as well as the protection of processed Personal Data. The processing of the Applicant's Personal Data is carried out by the company in strict accordance ",
            '9': "with the Policy on Processing and Protecting Personal Data at LLC 'RVM'.",
            '10': "The Applicant confirms that this consent is valid for the period of storage of the Applicant's Personal Data by the Company, established by the legislation of the Russian Federation.",
            '11': "The Applicant has the right to withdraw their consent at any time by submitting a corresponding written notice to the Company, sent to the Company's location address at least one month before the date of termination of the processing of Personal Data. The Applicant is informed that, in the event of the withdrawal of their consent to the processing of Personal Data, the Company may not be able to provide the Applicant with the information requested according to the 'Feedback Form'.",
            '12': 'This consent is given for the performance of any actions with respect to Personal Data that are necessary or desirable to achieve the above purpose, including, without limitation:',
            '13': 'Mixed processing;',
            '14': 'transfer via the internal network;',
            '15': 'transfer via the Internet;',
            '16': 'collection, systematization, accumulation, storage, clarification (updating or changing), use, transfer (provision, access), anonymization, blocking, destruction, deletion,',
            '17': "as well as the performance of any other actions with the Applicant's Personal Data in accordance with applicable law.",
            '18': "I understand that by clicking the 'Submit' button on the website https://rvm-spb.ru/, I give LLC 'RVM' my consent to the processing of my personal data under the conditions set forth above.",
        },
    },
    policy: {
        title: 'Policy on the Processing and Protection of Personal Data at LLC «RVM»',
        text: {
            '1': '1. General Provisions',
            '2': 'This personal data processing policy is drawn up in accordance with the requirements of Federal Law dated 27.07.2006. No. 152-FZ "On Personal Data" (hereinafter referred to as the Personal Data Law) and defines the procedure for processing personal data and measures to ensure the security of personal data. These measures are undertaken by LLC «RVM» (hereinafter referred to as the Operator).',
            '3': '1.1. The Operator considers compliance with the rights and freedoms of an individual and citizen when processing their personal data, including the protection of the rights to privacy, personal and family secrets, as its most important goal and condition for its activities.',
            '4': '1.2. This policy of the Operator regarding the processing of personal data (hereinafter referred to as the Policy) applies to all information that the Operator may obtain about visitors to the website https://www.rvm-spb.ru/',
            '5': '2. Basic Concepts Used in the Policy',
            '6': '2.1. Automated processing of personal data is the processing of personal data using computer technology.',
            '7': '2.2. Blocking of personal data is a temporary cessation of the processing of personal data (except in cases where processing is necessary to clarify personal data).',
            '8': '2.3. Website is a set of graphic and information materials, as well as computer programs and databases that ensure their availability on the internet at the network address https://www.rvm-spb.ru/',
            '9': '2.4. Information system of personal data is a set of personal data contained in databases and information technologies and technical means that ensure their processing.',
            '10': '2.5. Depersonalization of personal data is actions as a result of which it is impossible to determine without the use of additional information the belonging of personal data to a specific User or other subject of personal data.',
            '11': '2.6. Processing of personal data is any action (operation) or set of actions (operations) performed using automation tools or without using such tools with personal data, including collection, recording, systematization, accumulation, storage, clarification (updating, changing), extraction, use, transfer (distribution, provision, access), depersonalization, blocking, deletion, destruction of personal data.',
            '12': '2.7. Operator is a state body, municipal body, legal or physical person that independently or jointly with other persons organizes and/or carries out the processing of personal data, as well as determines the purposes of processing personal data, the composition of personal data to be processed, actions (operations) performed with personal data.',
            '13': '2.8. Personal data is any information relating directly or indirectly to a specific or identifiable User of the website https://www.rvm-spb.ru/',
            '14': '2.9. Personal data permitted by the subject of personal data for distribution is personal data to which an unlimited number of persons have access provided by the subject of personal data by giving consent to the processing of personal data permitted by the subject of personal data for distribution in the manner prescribed by the Personal Data Law (hereinafter referred to as personal data permitted for distribution).',
            '15': '2.10. User is any visitor to the website https://www.rvm-spb.ru/',
            '16': '2.11. Provision of personal data is actions aimed at disclosing personal data to a specific person or a specific circle of persons.',
            '17': '2.12. Distribution of personal data is any actions aimed at disclosing personal data to an indefinite circle of persons (transfer of personal data) or at familiarizing an unlimited circle of persons with personal data, including disclosing personal data in the media, posting it in information and telecommunications networks or providing access to personal data in any other way.',
            '18': '2.13. Cross-border transfer of personal data is the transfer of personal data to the territory of a foreign state to a foreign government authority, a foreign physical or foreign legal person.',
            '19': '2.14. Destruction of personal data is any actions as a result of which personal data is destroyed irrevocably with the impossibility of further restoration of the content of personal data in the personal data information system and/or the material carriers of personal data are destroyed.',
            '20': '3. Main Rights and Obligations of the Operator',
            '21': '3.1. The Operator has the right to:\n— receive reliable information and/or documents containing personal data from the subject of personal data;\n— in case of withdrawal by the subject of personal data of consent to the processing of personal data, as well as, sending an application demanding to stop the processing of personal data, the Operator has the right to continue processing personal data without the consent of the subject of personal data if there are grounds specified in the Personal Data Law;\n— independently determine the composition and list of measures necessary and sufficient to ensure the fulfillment of the obligations provided for by the Personal Data Law and the regulatory legal acts adopted in accordance with it, unless otherwise provided by the Personal Data Law or other federal laws.',
            '22': '3.2. The Operator is obliged to:\n— provide the subject of personal data at his request with information concerning the processing of his personal data;\n— organize the processing of personal data in accordance with the current legislation of the Russian Federation;\n— respond to requests and inquiries from subjects of personal data and their legal representatives in accordance with the requirements of the Personal Data Law;\n— report to the authorized body for the protection of the rights of subjects of personal data at the request of this body the necessary information within 10 days from the date of receipt of such a request;\n— publish or otherwise provide unrestricted access to this Policy regarding the processing of personal data;\n— take legal, organizational, and technical measures to protect personal data from unauthorized or accidental access, destruction, alteration, blocking, copying, provision, distribution of personal data, as well as from other unlawful actions regarding personal data;\n— stop transferring (distributing, providing, accessing) personal data, stop processing and destroy personal data in the manner and cases provided for by the Personal Data Law;\n— fulfill other obligations provided for by the Personal Data Law.',
            '23': '4. Main Rights and Obligations of Personal Data Subjects',
            '24': '4.1. Personal data subjects have the right to:\n— receive information regarding the processing of their personal data, except in cases provided for by federal laws. Information is provided to the subject of personal data by the Operator in an accessible form, and it should not contain personal data related to other subjects of personal data, except in cases where there are legal grounds for disclosing such personal data. The list of information and the procedure for obtaining it is established by the Personal Data Law;\n— demand from the operator clarification of their personal data, their blocking, or destruction in case the personal data are incomplete, outdated, inaccurate, illegally obtained, or not necessary for the stated purpose of processing, as well as take measures provided by law to protect their rights;\n— put forward a condition of prior consent when processing personal data for the purpose of promoting goods, works, and services on the market;\n— withdraw consent to the processing of personal data, as well as send a request to stop processing personal data;\n— complain to the authorized body for the protection of the rights of subjects of personal data or in court about unlawful actions or inaction of the Operator when processing their personal data;\n— exercise other rights provided for by the legislation of the Russian Federation.',
            '25': '4.2. Personal data subjects are obliged to:\n— provide the Operator with reliable data about themselves;\n— inform the Operator about the clarification (update, change) of their personal data.',
            '26': '4.3. Persons who have provided the Operator with false information about themselves, or information about another subject of personal data without the consent of the latter, are liable in accordance with the legislation of the Russian Federation.',
            '27': '5. Principles of Personal Data Processing',
            '28': '5.1. The processing of personal data is carried out on a lawful and fair basis.',
            '29': '5.2. The processing of personal data is limited to the achievement of specific, predetermined, and legitimate goals. It is not allowed to process personal data incompatible with the purposes of collecting personal data.',
            '30': '5.3. It is not allowed to combine databases containing personal data, the processing of which is carried out for purposes incompatible with each other.',
            '31': '5.4. Only personal data that meet the purposes of their processing are subject to processing.',
            '32': '5.5. The content and volume of processed personal data correspond to the stated purposes of processing. Excessive personal data processed in relation to the stated purposes of their processing is not allowed.',
            '33': '5.6. When processing personal data, the accuracy of personal data, their sufficiency, and, if necessary, relevance in relation to the purposes of processing personal data are ensured. The Operator takes the necessary measures and/or ensures their adoption to remove or clarify incomplete or inaccurate data.',
            '34': '5.7. The storage of personal data is carried out in a form that allows determining the subject of personal data, no longer than required by the purposes of processing personal data, unless the period for storing personal data is established by federal law, a contract to which the subject of personal data is a party, a beneficiary, or a guarantor. The processed personal data are destroyed or depersonalized upon achieving the purposes of processing or in case of loss of the necessity to achieve these purposes, unless otherwise provided by federal law.',
            '35': '6. Conditions for Personal Data Processing',
            '36': '6.1. The processing of personal data is carried out with the consent of the subject of personal data to the processing of their personal data.',
            '37': '6.2. The processing of personal data is necessary to achieve the goals stipulated by an international treaty of the Russian Federation or by law, to perform the functions, powers, and duties assigned to the operator by the legislation of the Russian Federation.',
            '38': '6.3. The processing of personal data is necessary for the administration of justice, the execution of a judicial act, an act of another body or official, subject to execution in accordance with the legislation of the Russian Federation on enforcement proceedings.',
            '39': '6.4. The processing of personal data is necessary for the execution of a contract to which the subject of personal data is a party, a beneficiary, or a guarantor, as well as for the conclusion of a contract on the initiative of the subject of personal data or a contract under which the subject of personal data will be a beneficiary or a guarantor.',
            '40': '6.5. The processing of personal data is necessary for the exercise of the rights and legitimate interests of the operator or third parties or to achieve socially significant goals, provided that this does not violate the rights and freedoms of the subject of personal data.',
            '41': '6.6. The processing of personal data, access to which is provided to an unlimited number of persons by the subject of personal data or at their request (hereinafter referred to as publicly available personal data), is carried out.',
            '42': '6.7. The processing of personal data subject to publication or mandatory disclosure in accordance with federal law is carried out.',
            '43': '7. Procedure for Collecting, Storing, Transferring, and Other Types of Personal Data Processing',
            '44': 'The security of personal data processed by the Operator is ensured through the implementation of legal, organizational, and technical measures necessary to fully comply with the requirements of the current legislation in the field of personal data protection.',
            '45': '7.1. The Operator ensures the security of personal data and takes all possible measures to prevent unauthorized access to personal data.',
            '46': '7.2. Personal data of the User will never, under any circumstances, be transferred to third parties, except in cases related to the implementation of current legislation or if the subject of personal data has given the Operator consent to transfer data to a third party to fulfill obligations under a civil contract.',
            '47': '7.3. In case of inaccuracies in personal data, the User can update them independently by sending a notification to the Operator\'s email address info&#64;rvm-spb.ru marked "Personal Data Update".',
            '48': '7.4. The period for processing personal data is determined by the achievement of the purposes for which the personal data was collected unless otherwise provided by the contract or current legislation. The User may at any time withdraw their consent to the processing of personal data by sending a notification to the Operator via email to the Operator\'s email address info&#64;rvm-spb.ru marked "Withdrawal of Consent to Personal Data Processing".',
            '49': '7.5. All information that is collected by third-party services, including payment systems, communication tools, and other service providers, is stored and processed by the specified persons (Operators) in accordance with their User Agreement and Privacy Policy. The subject of personal data and/or with these documents. The Operator is not responsible for the actions of third parties, including the service providers mentioned in this paragraph.',
            '50': '7.6. Restrictions established by the subject of personal data on the transfer (except for access provision), as well as on the processing or conditions of processing (except for access obtaining) of personal data permitted for distribution, do not apply in cases of processing personal data in state, public and other public interests as defined by Russian law.',
            '51': '7.7. The Operator ensures the confidentiality of personal data during its processing.',
            '52': "7.8. When delivering goods, the Operator may collect data on the delivery endpoint and recipient to accurately calculate the route. Freight transportation is carried out using transport companies and courier delivery services. Prices according to the carrier's tariffs depend on the volume and weight of the cargo, as well as the distance from the point of dispatch. Special conditions apply for oversized and heavy cargo, the cost can be calculated through the NNV-Negabarit website.",
            '53': '7.9. The Operator stores personal data in a form that allows determining the subject of personal data no longer than required by the purposes of personal data processing unless the period for storing personal data is established by federal law, a contract to which the subject of personal data is a party, a beneficiary, or a guarantor.',
            '54': '7.10. The condition for stopping the processing of personal data may be the achievement of the purposes of personal data processing, the expiration of the consent period of the subject of personal data, the withdrawal of consent by the subject of personal data, or a requirement to stop the processing of personal data, as well as the detection of unlawful processing of personal data.',
            '55': '8. List of Actions Performed by the Operator with the Received Personal Data',
            '56': '8.1. The Operator performs the collection, recording, systematization, accumulation, storage, clarification (updating, changing), extraction, use, transfer (distribution, provision, access), depersonalization, blocking, deletion, and destruction of personal data.',
            '57': '8.2. The Operator performs automated processing of personal data with the receipt and/or transmission of the received information through information and telecommunications networks or without such.',
            '58': '9. Cross-Border Transfer of Personal Data',
            '59': '9.1. The Operator, before starting activities on the cross-border transfer of personal data, is obliged to notify the authorized body for the protection of the rights of subjects of personal data of its intention to carry out the cross-border transfer of personal data (such notification is sent separately from the notification of the intention to carry out personal data processing).',
            '60': '9.2. The Operator, before submitting the above notification, must obtain the relevant information from the authorities of the foreign state, foreign individuals, foreign legal entities to whom the cross-border transfer of personal data is planned.',
            '61': '10. Confidentiality of Personal Data',
            '62': 'The Operator and other persons who have gained access to personal data are obliged not to disclose to third parties and not to distribute personal data without the consent of the subject of personal data unless otherwise provided by federal law.',
            '63': '11. Final Provisions',
            '64': '11.1. The User can get any explanations on issues of interest regarding the processing of their personal data by contacting the Operator via email info&#64;rvm-spb.ru',
            '65': '11.2. Any changes to the personal data processing policy by the Operator will be reflected in this document. The Policy is valid indefinitely until it is replaced by a new version.',
            '66': '11.3. The current version of the Policy is freely available on the internet at https://www.rvm-spb.ru/',
        },
    },
};
